/* eslint-disable */
import type { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: string; output: string };
};

/** Autogenerated input type of CallTestWebPush */
export type CallTestWebPushInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  token: Scalars["String"]["input"];
};

/** Autogenerated input type of CancelCourse */
export type CancelCourseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  orderItemId: Scalars["String"]["input"];
};

/** Autogenerated input type of CancelOrderItem */
export type CancelOrderItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  orderItemId: Scalars["String"]["input"];
};

export type Catalog = {
  __typename?: "Catalog";
  categories: Array<CatalogCategory>;
  description: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  imageUrl: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  ordinal: Scalars["Int"]["output"];
  variations: Array<CatalogVariation>;
};

export type CatalogCategory = "Beverage" | "Course" | "Food";

export type CatalogSubCategory =
  | "Appetizer"
  | "BottleWineRed"
  | "BottleWineRose"
  | "BottleWineSparkling"
  | "BottleWineWhite"
  | "Course"
  | "CourseOption"
  | "Dessert"
  | "DimSum"
  | "GlassWine"
  | "Handy"
  | "LunchMenu"
  | "LunchSideMenu"
  | "Main"
  | "NonAlcoholic"
  | "NoodleRice"
  | "OtherDrink"
  | "Tea";

export type CatalogVariation = {
  __typename?: "CatalogVariation";
  description: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  ordinal: Scalars["Int"]["output"];
  price: Scalars["Int"]["output"];
};

/** Autogenerated input type of CompleteStaffCallRequest */
export type CompleteStaffCallRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  visitId: Scalars["String"]["input"];
};

/** Autogenerated input type of CreateAuthSession */
export type DebugCreateAuthSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  email: Scalars["String"]["input"];
};

export type Mutation = {
  __typename?: "Mutation";
  callTestWebPush: Scalars["Boolean"]["output"];
  cancelCourse: Maybe<Scalars["Boolean"]["output"]>;
  cancelOrderItem: Maybe<Scalars["Boolean"]["output"]>;
  completeStaffCallRequest: Visit;
  debugCreateAuthSession: ShopUser;
  serveOrderItem: Maybe<Scalars["Boolean"]["output"]>;
  subscribeWebPush: Scalars["Boolean"]["output"];
  undoOrderItem: Maybe<Scalars["Boolean"]["output"]>;
  unsubscribeWebPush: Scalars["Boolean"]["output"];
};

export type MutationCallTestWebPushArgs = {
  input: CallTestWebPushInput;
};

export type MutationCancelCourseArgs = {
  input: CancelCourseInput;
};

export type MutationCancelOrderItemArgs = {
  input: CancelOrderItemInput;
};

export type MutationCompleteStaffCallRequestArgs = {
  input: CompleteStaffCallRequestInput;
};

export type MutationDebugCreateAuthSessionArgs = {
  input: DebugCreateAuthSessionInput;
};

export type MutationServeOrderItemArgs = {
  input: ServeOrderItemInput;
};

export type MutationSubscribeWebPushArgs = {
  input: SubscribeWebPushInput;
};

export type MutationUndoOrderItemArgs = {
  input: UndoOrderItemInput;
};

export type MutationUnsubscribeWebPushArgs = {
  input: UnsubscribeWebPushInput;
};

export type Order = {
  __typename?: "Order";
  createdAt: Scalars["ISO8601DateTime"]["output"];
  dailySequence: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  orderItems: Array<OrderItem>;
  visit: Visit;
};

export type OrderItem = {
  __typename?: "OrderItem";
  amount: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  price: Scalars["Int"]["output"];
  quantity: Scalars["Int"]["output"];
  status: OrderItemStatus;
  subCategory: CatalogSubCategory;
  variation: Maybe<Scalars["String"]["output"]>;
};

export type OrderItemStatus = "Canceled" | "Pending" | "Served";

export type Query = {
  __typename?: "Query";
  /** Fetches a list of catalogs. */
  catalogs: Array<Catalog>;
  courses: Array<Order>;
  currentEndpointSubscribed: Scalars["Boolean"]["output"];
  currentShop: Maybe<Shop>;
  currentShopUser: Maybe<ShopUser>;
  orders: Array<Order>;
};

export type QueryCurrentEndpointSubscribedArgs = {
  token: Scalars["String"]["input"];
};

/** Autogenerated input type of ServeOrderItem */
export type ServeOrderItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  orderItemId: Scalars["String"]["input"];
};

export type Shop = {
  __typename?: "Shop";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  visits: Array<Visit>;
};

export type ShopVisitsArgs = {
  actionRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ShopUser = {
  __typename?: "ShopUser";
  currentShop: Maybe<Shop>;
  email: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  shops: Array<Shop>;
};

export type StaffCallRequest = {
  __typename?: "StaffCallRequest";
  createdAt: Scalars["ISO8601DateTime"]["output"];
  id: Scalars["ID"]["output"];
  requestType: StaffCallRequestType;
};

export type StaffCallRequestType = "CallSommelier" | "CallStaff";

/** Autogenerated input type of SubscribeWebPush */
export type SubscribeWebPushInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  token: Scalars["String"]["input"];
};

/** Autogenerated input type of UndoOrderItem */
export type UndoOrderItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  orderItemId: Scalars["String"]["input"];
};

/** Autogenerated input type of UnsubscribeWebPush */
export type UnsubscribeWebPushInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  token: Scalars["String"]["input"];
};

export type Visit = {
  __typename?: "Visit";
  createdAt: Scalars["ISO8601DateTime"]["output"];
  id: Scalars["ID"]["output"];
  settled: Scalars["Boolean"]["output"];
  /** 最も古いスタッフ呼び出しリクエスト */
  staffCallRequest: Maybe<StaffCallRequest>;
  tableName: Scalars["String"]["output"];
};

export type FetchCurrentEndpointSubscribedQueryVariables = Exact<{
  token: Scalars["String"]["input"];
}>;

export type FetchCurrentEndpointSubscribedQuery = {
  __typename?: "Query";
  currentEndpointSubscribed: boolean;
};

export type ServeOrderItemMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type ServeOrderItemMutation = {
  __typename?: "Mutation";
  serveOrderItem: boolean | null;
};

export type CancelOrderItemMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type CancelOrderItemMutation = {
  __typename?: "Mutation";
  cancelOrderItem: boolean | null;
};

export type UndoOrderItemMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type UndoOrderItemMutation = {
  __typename?: "Mutation";
  undoOrderItem: boolean | null;
};

export type FetchOrdersQueryVariables = Exact<{ [key: string]: never }>;

export type FetchOrdersQuery = {
  __typename?: "Query";
  orders: Array<
    { __typename?: "Order" } & {
      " $fragmentRefs"?: { OrderUnitFragment: OrderUnitFragment };
    }
  >;
};

export type OrderUnitFragment = {
  __typename?: "Order";
  id: string;
  dailySequence: number;
  createdAt: string;
  orderItems: Array<{
    __typename?: "OrderItem";
    id: string;
    variation: string | null;
    name: string;
    price: number;
    subCategory: CatalogSubCategory;
    status: OrderItemStatus;
    quantity: number;
    amount: number;
  }>;
  visit: {
    __typename?: "Visit";
    id: string;
    createdAt: string;
    tableName: string;
    settled: boolean;
  };
} & { " $fragmentName"?: "OrderUnitFragment" };

export type SubscribeWebPushMutationVariables = Exact<{
  input: SubscribeWebPushInput;
}>;

export type SubscribeWebPushMutation = {
  __typename?: "Mutation";
  subscribeWebPush: boolean;
};

export type UnsubscribeWebPushMutationVariables = Exact<{
  input: UnsubscribeWebPushInput;
}>;

export type UnsubscribeWebPushMutation = {
  __typename?: "Mutation";
  unsubscribeWebPush: boolean;
};

export type CallTestWebPushMutationVariables = Exact<{
  input: CallTestWebPushInput;
}>;

export type CallTestWebPushMutation = {
  __typename?: "Mutation";
  callTestWebPush: boolean;
};

export type AuthProvider_DebugCreateAuthSessionMutationVariables = Exact<{
  input: DebugCreateAuthSessionInput;
}>;

export type AuthProvider_DebugCreateAuthSessionMutation = {
  __typename?: "Mutation";
  debugCreateAuthSession: { __typename?: "ShopUser" } & {
    " $fragmentRefs"?: {
      AuthProvider_ShopUserFragment: AuthProvider_ShopUserFragment;
    };
  };
};

export type AuthProvider_ShopUserFragment = {
  __typename?: "ShopUser";
  id: string;
  email: string;
  shops: Array<{ __typename?: "Shop"; id: string; name: string }>;
  currentShop: { __typename?: "Shop"; id: string; name: string } | null;
} & { " $fragmentName"?: "AuthProvider_ShopUserFragment" };

export type AuthProvider_ShopUserQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AuthProvider_ShopUserQuery = {
  __typename?: "Query";
  currentShopUser:
    | ({ __typename?: "ShopUser" } & {
        " $fragmentRefs"?: {
          AuthProvider_ShopUserFragment: AuthProvider_ShopUserFragment;
        };
      })
    | null;
};

export type FetchCurrentShop_StaffCallRequestQueryVariables = Exact<{
  [key: string]: never;
}>;

export type FetchCurrentShop_StaffCallRequestQuery = {
  __typename?: "Query";
  currentShop: {
    __typename?: "Shop";
    id: string;
    visits: Array<
      { __typename?: "Visit" } & {
        " $fragmentRefs"?: {
          Visit_StaffCallRequestFragment: Visit_StaffCallRequestFragment;
        };
      }
    >;
  } | null;
};

export type Visit_StaffCallRequestFragment = {
  __typename?: "Visit";
  id: string;
  tableName: string;
  staffCallRequest: {
    __typename?: "StaffCallRequest";
    id: string;
    createdAt: string;
    requestType: StaffCallRequestType;
  } | null;
} & { " $fragmentName"?: "Visit_StaffCallRequestFragment" };

export type CompleteStaffCallRequestMutationVariables = Exact<{
  input: CompleteStaffCallRequestInput;
}>;

export type CompleteStaffCallRequestMutation = {
  __typename?: "Mutation";
  completeStaffCallRequest: { __typename?: "Visit"; id: string };
};

export const OrderUnitFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "OrderUnit" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Order" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "dailySequence" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "orderItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "variation" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "price" } },
                { kind: "Field", name: { kind: "Name", value: "subCategory" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "quantity" } },
                { kind: "Field", name: { kind: "Name", value: "amount" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "visit" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "tableName" } },
                { kind: "Field", name: { kind: "Name", value: "settled" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrderUnitFragment, unknown>;
export const AuthProvider_ShopUserFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AuthProvider_ShopUser" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ShopUser" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shops" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "currentShop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuthProvider_ShopUserFragment, unknown>;
export const Visit_StaffCallRequestFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Visit_StaffCallRequest" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Visit" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "tableName" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "staffCallRequest" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "requestType" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Visit_StaffCallRequestFragment, unknown>;
export const FetchCurrentEndpointSubscribedDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "FetchCurrentEndpointSubscribed" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "token" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "currentEndpointSubscribed" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "token" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "token" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FetchCurrentEndpointSubscribedQuery,
  FetchCurrentEndpointSubscribedQueryVariables
>;
export const ServeOrderItemDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ServeOrderItem" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "serveOrderItem" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "orderItemId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ServeOrderItemMutation,
  ServeOrderItemMutationVariables
>;
export const CancelOrderItemDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CancelOrderItem" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "cancelOrderItem" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "orderItemId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CancelOrderItemMutation,
  CancelOrderItemMutationVariables
>;
export const UndoOrderItemDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UndoOrderItem" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "undoOrderItem" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "orderItemId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UndoOrderItemMutation,
  UndoOrderItemMutationVariables
>;
export const FetchOrdersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "FetchOrders" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "orders" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "OrderUnit" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "OrderUnit" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Order" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "dailySequence" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "orderItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "variation" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "price" } },
                { kind: "Field", name: { kind: "Name", value: "subCategory" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "quantity" } },
                { kind: "Field", name: { kind: "Name", value: "amount" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "visit" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "tableName" } },
                { kind: "Field", name: { kind: "Name", value: "settled" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchOrdersQuery, FetchOrdersQueryVariables>;
export const SubscribeWebPushDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SubscribeWebPush" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "SubscribeWebPushInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "subscribeWebPush" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubscribeWebPushMutation,
  SubscribeWebPushMutationVariables
>;
export const UnsubscribeWebPushDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UnsubscribeWebPush" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UnsubscribeWebPushInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "unsubscribeWebPush" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UnsubscribeWebPushMutation,
  UnsubscribeWebPushMutationVariables
>;
export const CallTestWebPushDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CallTestWebPush" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CallTestWebPushInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "callTestWebPush" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CallTestWebPushMutation,
  CallTestWebPushMutationVariables
>;
export const AuthProvider_DebugCreateAuthSessionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AuthProvider_DebugCreateAuthSession" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DebugCreateAuthSessionInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "debugCreateAuthSession" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "AuthProvider_ShopUser" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AuthProvider_ShopUser" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ShopUser" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shops" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "currentShop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AuthProvider_DebugCreateAuthSessionMutation,
  AuthProvider_DebugCreateAuthSessionMutationVariables
>;
export const AuthProvider_ShopUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AuthProvider_ShopUser" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "currentShopUser" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "AuthProvider_ShopUser" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AuthProvider_ShopUser" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ShopUser" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shops" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "currentShop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AuthProvider_ShopUserQuery,
  AuthProvider_ShopUserQueryVariables
>;
export const FetchCurrentShop_StaffCallRequestDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "FetchCurrentShop_StaffCallRequest" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "currentShop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "visits" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "actionRequired" },
                      value: { kind: "BooleanValue", value: true },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "Visit_StaffCallRequest" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Visit_StaffCallRequest" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Visit" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "tableName" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "staffCallRequest" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "requestType" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FetchCurrentShop_StaffCallRequestQuery,
  FetchCurrentShop_StaffCallRequestQueryVariables
>;
export const CompleteStaffCallRequestDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CompleteStaffCallRequest" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CompleteStaffCallRequestInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "completeStaffCallRequest" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompleteStaffCallRequestMutation,
  CompleteStaffCallRequestMutationVariables
>;
