import { ApolloProvider } from "@apollo/client";
import { AppProps } from "next/app";
import Head from "next/head";

import { FontsLoader } from "../components/FontLoader";

import { AuthorizedGuard } from "src/components/AuthorizedGuard";
import { useApollo } from "src/hooks/useApollo";
import { AuthProvider } from "src/providers/AuthProvider";
import { KitchenChakraProvider } from "src/providers/KitchenChakraProvider";
import { OrderProvider } from "src/providers/OrderProvider";
import { PWADeviceProvider } from "src/providers/PWADeviceProvider";
import { ServiceWorkerProvider } from "src/providers/ServiceWorkerProvider";
import { StaffCallRequestProvider } from "src/providers/StaffCallRequestProvider";

const App = ({ Component, pageProps }: AppProps) => {
  const client = useApollo(pageProps.initialApolloState);

  return (
    <>
      <Head>
        <title>Speria for Kitchen</title>
      </Head>

      <ApolloProvider client={client}>
        <KitchenChakraProvider>
          <ServiceWorkerProvider>
            <AuthProvider>
              <FontsLoader>
                <AuthorizedGuard>
                  <OrderProvider>
                    <StaffCallRequestProvider>
                      <PWADeviceProvider>
                        <Component {...pageProps} />
                      </PWADeviceProvider>
                    </StaffCallRequestProvider>
                  </OrderProvider>
                </AuthorizedGuard>
              </FontsLoader>
            </AuthProvider>
          </ServiceWorkerProvider>
        </KitchenChakraProvider>
      </ApolloProvider>
    </>
  );
};

export default App;
