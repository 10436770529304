import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { useAuth } from "./AuthProvider";
import { useStaffCallRequest } from "./StaffCallRequestProvider";

import { useWebPushSubscription } from "src/hooks/useWebPushSubscription";
import { staticPath } from "src/utils/$path";

type PWADeviceProviderProps = {
  isMobile: boolean;
  isPWA: boolean;
  fetchCurrentEndpointSubscribed: () => Promise<boolean>;
  currentEndpointSubscribed: boolean;
};

const context = createContext<PWADeviceProviderProps>({
  isMobile: false,
  isPWA: false,
  fetchCurrentEndpointSubscribed: async () => false,
  currentEndpointSubscribed: false,
});

const { Provider } = context;

export const PWADeviceProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const ready = useRef<boolean>(false);

  useEffect(() => {
    ready.current = true;
  }, []);

  const { refetch } = useStaffCallRequest();

  useEffect(() => {
    const listener = (event: MessageEvent) => {
      if (!event.data) return;

      if (event.data.type === "TEST") {
        const sound = new Audio(staticPath.glockenspiel01_mp3);
        sound.play();

        return;
      }

      if (event.data.type === "NEW_ORDER") {
        const sound = new Audio(staticPath.glockenspiel01_mp3);
        sound.play();

        return;
      }

      if (event.data.type === "STAFF_CALL_REQUEST") {
        refetch();

        const sound = new Audio(staticPath.glockenspiel01_mp3);
        sound.play();

        return;
      }
    };

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", listener);
    }

    return () => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.removeEventListener("message", listener);
      }
    };
  }, [refetch]);

  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isPWA, setIsPWA] = useState<boolean>(false);
  // const isMobilePWA = useMemo(() => isMobile && isPWA, [isMobile, isPWA]);

  const { fetchCurrentEndpointSubscribed: fetch } = useWebPushSubscription();
  const { authState } = useAuth();

  const [currentEndpointSubscribed, setCurrentEndpointSubscribed] =
    useState<boolean>(false);

  const fetchCurrentEndpointSubscribed =
    useCallback(async (): Promise<boolean> => {
      if (!ready.current) return false;

      const subscribed = await fetch();

      setCurrentEndpointSubscribed(subscribed);

      return subscribed;
    }, [ready, fetch]);

  useEffect(() => {
    // NOTE: https://developer.mozilla.org/ja/docs/Web/HTTP/Browser_detection_using_the_user_agent#mobile_device_detection
    //       タッチスクリーンがあるデバイスは Surface など色々あるが、ひとまずタブレットかどうかを判定する
    setIsMobile("maxTouchPoints" in navigator && navigator.maxTouchPoints > 0);

    // NOTE: https://www.ykicchan.dev/posts/2021-03-26
    setIsPWA(window.matchMedia("(display-mode: standalone)").matches);

    const serviceWorker = navigator.serviceWorker;

    if (!ready.current) return;
    if (isMobile && !isPWA) return;

    // NOTE: iPad ブラウザでは `ServiceWorker` が undifined となるため、この条件は必要
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!serviceWorker) return;

    if (authState.status !== "authorized") return;

    fetchCurrentEndpointSubscribed();
  }, [
    fetchCurrentEndpointSubscribed,
    isMobile,
    isPWA,
    ready,
    authState.status,
  ]);

  return (
    <Provider
      value={{
        isMobile,
        isPWA,
        fetchCurrentEndpointSubscribed,
        currentEndpointSubscribed,
      }}
    >
      {children}
    </Provider>
  );
};

export const usePWADevice = (): PWADeviceProviderProps => {
  const {
    isMobile,
    isPWA,
    fetchCurrentEndpointSubscribed,
    currentEndpointSubscribed,
  } = useContext(context);

  return {
    isMobile,
    isPWA,
    fetchCurrentEndpointSubscribed,
    currentEndpointSubscribed,
  };
};
