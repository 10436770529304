import { ApolloQueryResult, useQuery } from "@apollo/client";
import { createContext, ReactNode, useContext, useMemo } from "react";

import { getFragmentData } from "src/gql/__generated__";
import {
  FetchOrdersDocument,
  FetchOrdersQuery,
  OrderUnitFragment,
  OrderUnitFragmentDoc,
} from "src/gql/__generated__/graphql";

type Props = {
  orders: readonly OrderUnitFragment[];
  refetch: () => Promise<ApolloQueryResult<FetchOrdersQuery>>;
};

const context = createContext<Props>({
  orders: [],
  refetch: async () => {
    throw new Error("Not implemented");
  },
});

const { Provider } = context;

export const OrderProvider = ({ children }: { children: ReactNode }) => {
  const { data, refetch } = useQuery(FetchOrdersDocument, {
    fetchPolicy: "no-cache",
    pollInterval: 10000,
  });

  const orders = useMemo(
    () =>
      data?.orders.map((order) =>
        getFragmentData(OrderUnitFragmentDoc, order),
      ) ?? [],
    [data],
  );

  return <Provider value={{ orders, refetch }}>{children}</Provider>;
};

export const useOrder = () => {
  const { orders, refetch } = useContext(context);

  return {
    orders,
    refetch,
  };
};
