import { LoadingBox } from "@repo/ui/loadingBox";
import { useRouter } from "next/router";
import { FC, ReactNode, useEffect } from "react";

import { useIdApp } from "src/hooks/useIdApp";
import { useAuth } from "src/providers/AuthProvider";
import { pagesPath } from "src/utils/$path";

const publicPaths: string[] = [
  "/_healthz",
  pagesPath.debug.auth.$url().pathname,
];

export const AuthorizedGuard: FC<{ children: ReactNode }> = ({ children }) => {
  const router = useRouter();
  const { authState, isLoading, check } = useAuth();
  const isPublicPath = publicPaths.includes(router.pathname);
  const { redirectToSignInUrl, redirectToSelectShopUrl } = useIdApp();

  // 認証状態が不確定であれば、チェックを行う
  useEffect(() => {
    if (isPublicPath) {
      return;
    }
    if (authState.status !== "unknown") {
      return;
    }
    if (isLoading) {
      return;
    }

    check();
  }, [isPublicPath, authState.status, isLoading, check]);

  // 認証状態が未認証であれば、ログイン画面に移動する
  useEffect(() => {
    if (isPublicPath) {
      return;
    }
    if (authState.status === "unauthorized") {
      if (process.env.NEXT_PUBLIC_ENABLE_DEBUG_LOGIN === "true") {
        if (window.confirm("開発者ログインを行いますか？")) {
          router.push(pagesPath.debug.auth.$url());
          return;
        }
      }
      redirectToSignInUrl();
      return;
    }

    if (authState.status === "authorized") {
      if (authState.shopUser.currentShop == null) {
        redirectToSelectShopUrl();
        return;
      }
    }
  }, [
    isPublicPath,
    authState.status,
    authState.shopUser?.currentShop,
    redirectToSignInUrl,
    redirectToSelectShopUrl,
    router,
  ]);

  if (isPublicPath) {
    return <>{children}</>;
  }
  if (authState.status === "authorized" && !isLoading) {
    return <>{children}</>;
  }

  return <LoadingBox />;
};
