export const pagesPath = {
  "$404": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/404' as const, hash: url?.hash })
  },
  "courses": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/courses' as const, hash: url?.hash })
  },
  "debug": {
    "auth": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/debug/auth' as const, hash: url?.hash })
    }
  },
  "orders": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/orders' as const, hash: url?.hash })
  },
  "settings": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/settings' as const, hash: url?.hash })
  },
  $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/' as const, hash: url?.hash })
};

export type PagesPath = typeof pagesPath;

export const staticPath = {
  circles_svg: '/circles.svg',
  firebase_messaging_sw_js: '/firebase-messaging-sw.js',
  glockenspiel01_mp3: '/glockenspiel01.mp3',
  icon_192x192_png: '/icon-192x192.png',
  icon_256x256_png: '/icon-256x256.png',
  icon_384x384_png: '/icon-384x384.png',
  icon_512x512_png: '/icon-512x512.png',
  manifest_json: '/manifest.json',
  next_svg: '/next.svg',
  turborepo_svg: '/turborepo.svg',
  vercel_svg: '/vercel.svg'
} as const;

export type StaticPath = typeof staticPath;
