import { NextPage } from "next";
import { useRouter } from "next/router";

import { pagesPath } from "src/utils/$path";

const Page: NextPage = () => {
  const router = useRouter();

  router.replace(pagesPath.orders.$url());

  return null;
};

export default Page;
